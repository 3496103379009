import React, { ReactElement } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Image } from '../utils/image';

const EngageEveryone = (): ReactElement => (
    <Row
        noGutters
        style={{
            paddingTop: `3rem`,
            paddingBottom: `5rem`,
            paddingLeft: `3rem`,
            paddingRight: `3rem`,
            backgroundImage: `linear-gradient(to bottom, #fffcf9, #fff8f0)`,
        }}
    >
        <Col lg="7" md="12">
            <Image src="images/engage-everyone.svg" />
        </Col>
        <Col lg="5" md="12">
            <div
                style={{
                    color: `#2e598f`,
                    fontSize: `2rem`,
                    paddingTop: `5rem`,
                }}
            >
                Engage with everyone
            </div>
            <div
                style={{
                    fontSize: `1.5rem`,
                    color: `#171717`,
                    paddingTop: `1rem`,
                    paddingBottom: `1rem`,
                }}
            >
                Data Transition
            </div>
            <p style={{ color: `#535b6c`, paddingBottom: `1rem` }}>
                {`Stackiam is simple, secure and connects everybody, whether
                they're behind a desk or in front of customers.`}
            </p>
            <p style={{ color: `#535b6c`, paddingBottom: `1rem` }}>
                Manage employee access through G Suite and Azure
            </p>
            <p style={{ color: `#535b6c`, paddingBottom: `1rem` }}>
                Get emailless workers on board with Access Codes
            </p>
            <p style={{ color: `#535b6c`, paddingBottom: `1rem` }}>
                Put safety first with Single Sign-On and Access Controls
            </p>
            <div
                style={{
                    color: `#535b6c`,
                    fontWeight: `bold`,
                    paddingTop: `1rem`,
                    paddingBottom: `1rem`,
                }}
            >
                Goto Single Sign-On and Access Controls
            </div>
        </Col>
    </Row>
);

export default EngageEveryone;
