import React from 'react';
import { Card, Carousel, Col, Container, Row } from 'react-bootstrap';
import AccessForEveryone from '../components/accessForEveryone';
import { Layout } from '../components/common';
import { ColoredButton } from '../components/common/Buttons';
import BannerSection from '../components/common/contentSections/BannerSection';
import FeatureCard from '../components/common/featureCard';
import EngageEveryone from '../components/engageEveryone';
import OurProduct from '../components/ourProduct';
import { Image } from '../utils/image';
import { Link } from 'gatsby';
import { cardData } from '../utils/entities';

const cardDetails: cardData[] = [
    {
        title: `B2B Integration`,
        description: `StackIAM allows you to integrate with other businesses, enterprise directory and give your business partners access to information they might need.`,
        imgUrl: `/images/b-2-b.svg`,
        slug: `/b2b`,
    },
    {
        title: `Access Gateway`,
        description: `StackIAM allows you to integrate with other businesses, enterprise directory and give your business partners access to information they might need.`,
        imgUrl: `/images/login.svg`,
        slug: `/access-gateway`,
    },
    {
        title: `Customer Dashboard`,
        description: `Customers can view their dashboard capturing who they are, settings, who has access and what kind of information they have access to.`,
        imgUrl: `/images/dashboard.svg`,
        slug: `/customer-dashboard`,
    },
    {
        title: `Secure authentication with SSO`,
        description: `Single sign on with SAML connects helps customers login into multiple applications with a single username and password.`,
        imgUrl: `/images/keyword.svg`,
        slug: `/secure-sso`,
    },
    {
        title: `Adaptive Multi factor Authentication`,
        description: `WorkForce / Employee Identity management - Useful Unified view to view all employees and assign them into different groups based on different departments and permission to different resources. `,
        imgUrl: `/images/multi.svg`,
        slug: `/multifactor`,
    },
    {
        title: `Data verification & Management`,
        description: `Verify and authenticate all your information, manage access, secure and customize user login flow`,
        imgUrl: `/images/correct.svg`,
        slug: `/data-verification`,
    },
    {
        title: `Easy Application`,
        description: `Apply easily to any company or any other form of application with your already aggregated verified information, using your unique and  secured login credentials without having to fill any more forms ever again.`,
        imgUrl: `/images/easy-application.svg`,
        slug: `/easy-application`,
    },
    {
        title: `Business Service`,
        description: `Verify and authenticate all your information, manage access, secure and customize user login flow`,
        imgUrl: `/images/business-service.svg`,
        slug: `/business-service`,
    },
];

const Index = () => (
    <>
        <Layout>
            <Container>
                <Row
                    noGutters
                    style={{
                        backgroundImage: `linear-gradient(to bottom, #fffcf9, #fff8f0)`,
                    }}
                >
                    <Col
                        md="12"
                        lg="5"
                        style={{ paddingLeft: `3rem`, paddingTop: `2rem` }}
                    >
                        <div
                            style={{
                                fontSize: `3rem`,
                                lineHeight: `4rem`,
                                color: `#2e598f`,
                            }}
                        >
                            We build secured identity access for everyone
                        </div>
                        <div
                            style={{
                                paddingTop: `1rem`,
                                fontSize: `1.05rem`,
                                color: `#e13130`,
                            }}
                        >
                            StackIAM cloud provides a trusted one-stop platform
                            to seamlessly validate identity for organizations
                            and businesses
                        </div>
                        <div
                            style={{ paddingTop: `1rem`, textAlign: `center` }}
                        >
                            <ColoredButton>Get Started</ColoredButton>
                        </div>
                    </Col>
                    <Col
                        md="12"
                        lg="7"
                        style={{ paddingLeft: `3rem`, paddingTop: `2rem` }}
                    >
                        <Image src="/images/home-hero@3x.png" />
                    </Col>
                </Row>
                <Row noGutters>
                    <Col
                        xs="12"
                        style={{
                            textAlign: `center`,
                            paddingTop: `3rem`,
                            color: `#2e598f`,
                        }}
                    >
                        <div
                            style={{
                                fontSize: `2rem`,
                            }}
                        >
                            Our Core Solution
                        </div>
                    </Col>

                    <Col
                        md="12"
                        lg="7"
                        style={{ paddingLeft: `3rem`, paddingTop: `2rem` }}
                    >
                        <Image src="/images/home-core-solution@3x.png" />
                    </Col>
                    <Col
                        md="12"
                        lg="5"
                        style={{ paddingLeft: `3rem`, paddingTop: `2rem` }}
                    >
                        <div>
                            <Image
                                style={{ height: `2rem`, width: `2rem` }}
                                src="/images/home-multifactor.svg"
                            />
                        </div>
                        <div
                            style={{
                                fontSize: `1.5rem`,
                                color: `#171717`,
                                paddingTop: `0.75rem`,
                                paddingBottom: `0.75rem`,
                            }}
                        >
                            Multifactor Authentication
                        </div>
                        <div style={{ color: `#535b6c` }}>
                            Need to look fabulous, fast? Search. Book. Enjoy.
                            Instantly book your next beauty or wellness
                            experience.
                        </div>
                        <div style={{ paddingTop: `1rem` }}>
                            <Image
                                style={{ height: `2rem`, width: `2rem` }}
                                src="/images/home-sso.svg"
                            />
                        </div>
                        <div
                            style={{
                                fontSize: `1.5rem`,
                                color: `#171717`,
                                paddingTop: `0.75rem`,
                                paddingBottom: `0.75rem`,
                            }}
                        >
                            Single Sign On
                        </div>
                        <div style={{ color: `#535b6c` }}>
                            Allows enterprise, small organizations, and
                            individual users to easily manage their
                            authenticated login credentials.
                        </div>
                        <div style={{ paddingTop: `1rem` }}>
                            <Image
                                style={{ height: `2rem`, width: `2rem` }}
                                src="/images/home-user-management.svg"
                            />
                        </div>
                        <div
                            style={{
                                fontSize: `1.5rem`,
                                color: `#171717`,
                                paddingTop: `0.75rem`,
                                paddingBottom: `0.75rem`,
                            }}
                        >
                            User management
                        </div>
                        <div style={{ color: `#535b6c` }}>
                            Administrators can manage who has access to your
                            application and information; user’s identities
                            including password reset, adding and deleting users.
                            StackIAM provides the easiest way to manage user
                            identities via APIs or user dashboard.
                        </div>
                    </Col>
                </Row>
                <Row
                    noGutters
                    style={{
                        backgroundImage: `linear-gradient(to bottom, #fffcf9, #fff8f0)`,
                    }}
                >
                    <Col
                        xs="12"
                        style={{
                            textAlign: `center`,
                            paddingTop: `3rem`,
                            paddingBottom: `3rem`,
                            color: `#2e598f`,
                        }}
                    >
                        <div
                            style={{
                                fontSize: `2rem`,
                            }}
                            id="features"
                        >
                            The Features
                        </div>
                    </Col>
                </Row>
                <Row
                    noGutters
                    style={{
                        backgroundImage: `linear-gradient(to bottom, #fffcf9, #fff8f0)`,
                        paddingLeft: `3rem`,
                        paddingRight: `3rem`,
                        paddingBottom: `3rem`,
                    }}
                >
                    <Col md="12" lg="4">
                        <Card
                            style={{
                                backgroundColor: `#36a9f5`,
                                color: `#ffffff`,
                            }}
                        >
                            <Card.Body style={{ textAlign: `center` }}>
                                <Image
                                    style={{
                                        height: `5rem`,
                                        width: `5rem`,
                                        marginTop: `2rem`,
                                        marginBottom: `2rem`,
                                    }}
                                    src="/images/process.svg"
                                />
                                <div
                                    style={{
                                        fontSize: `1.05rem`,
                                        marginBottom: `1rem`,
                                        fontWeight: `bold`,
                                    }}
                                >
                                    <Link
                                        style={{
                                            color: `#ffffff`,
                                            textDecoration: `none`,
                                        }}
                                        to="/lifecycle"
                                    >
                                        Life cycle management
                                    </Link>
                                </div>
                                <p style={{ height: `7rem` }}>
                                    Use StackIAM to coordinate and restrict the
                                    identification, access and governance of
                                    identities for access to your business tools
                                    and information.
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>
                    {cardDetails.map(card => (
                        <FeatureCard
                            key={card.title}
                            imgUrl={card.imgUrl}
                            description={card.description}
                            title={card.title}
                            slug={card.slug}
                        />
                    ))}
                </Row>
                <AccessForEveryone />
                <OurProduct />
                <BannerSection />
                <EngageEveryone />
                <Row noGutters style={{ marginBottom: `3rem` }}>
                    <Col xs="12">
                        <div
                            style={{
                                color: `#2e598f`,
                                fontSize: `2rem`,
                                textAlign: `center`,
                                paddingTop: `3rem`,
                                paddingBottom: `3rem`,
                            }}
                            id="products"
                        >
                Testimonies
                        </div>
                    </Col>
                    <Col xs="12">
                        <Carousel>      
                            { [{ src: `images/profile2.jpg`, 
                                testimonial: `“With Workplace, we've seen the inspiration and innovation that come from connecting people who have never been able to interact before.”
`, 
                                by: `Alison Mogan, Global Internal Communications` },
                            { src: `images/profile.jpg`, 
                                testimonial: `“With Workplace, we've seen the inspiration and innovation that come from connecting people who have never been able to interact before.”
`, 
                                by: `Alison Mogan, Global Internal Communications` }].map((slide, i) => (
                                <Carousel.Item key={i}>
                                    <Row noGutters>
                                        <Col xs="12" md="4" style={{height: `20rem`}}>
                                        <img style={{ width: `100%`, height: `100%` }} src={slide.src} />
                                        </Col>
                                        <Col xs="12" md="8" style={{height: "20rem"}}>
                                        <div style={{ borderRadius: `0.2rem`, width: `100%`, height: `100%`,
                                            backgroundColor: `#f5f5f5` }}>
                                            <div><img src="images/twitter.svg" style={{ width: `6rem`, height: `6rem`, marginTop: `0.5rem`, paddingLeft: `3rem` }} /></div>
                                            <div style={{ paddingLeft: `3rem`, paddingRight: `7rem`, fontSize: `1.3rem`, fontWeight: `bold` }}>
                                                {slide.testimonial}
                                            </div> 
                                            <div style={{ marginTop: `0.5rem`, paddingLeft: `3rem`, paddingRight: `7rem`, paddingBottom: `1rem`, color: `#535b6c`, fontWeight: `bold` }}>{slide.by}</div>
                                        </div>
                                        </Col>
                                    </Row>
                                </Carousel.Item>
					
                            )) }
                        </Carousel>
                    </Col>
                </Row>
            </Container>
        </Layout>
    </>
);

export default Index;
