import styled from '@emotion/styled';
import React, { ReactElement } from 'react';
import { Col, Row, Card, Button } from 'react-bootstrap';
import { Image } from '../utils/image';

const StyledButton = styled(Button)`
    border-radius: 100rem;
    color: #29558c;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-right: 2rem;
    border-style: solid;
    border-width: 0.5px;
    border-image-source: linear-gradient(to top, #9ba4d9, #94dfe7);
    border-image-slice: 1;
    background-image: linear-gradient(to bottom, #ffffff, #ffffff),
        linear-gradient(to top, #9ba4d9, #94dfe7);
    background-origin: border-box;
    background-clip: content-box, border-box;
    border: solid 1px transparent;
    box-shadow: 2px 1000px 1px #fff inset;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    &:hover {
        color: black;
    }
`;

const OurProduct = (): ReactElement => (
    <Row
        noGutters
        style={{
            paddingTop: `3rem`,
            paddingBottom: `5rem`,
            paddingLeft: `3rem`,
            paddingRight: `3rem`,
            backgroundImage: `linear-gradient(to bottom, #fffcf9, #fff8f0)`,
        }}
    >
        <Col xs="12">
            <div
                style={{
                    color: `#2e598f`,
                    fontSize: `2rem`,
                    textAlign: `center`,
                    paddingBottom: `3rem`,
                }}
                id="products"
            >
                Our Product
            </div>
        </Col>
        <Col md="12" lg="4">
            <Card>
                <Card.Body>
                    <Image
                        style={{
                            height: `4rem`,
                            width: `4rem`,
                            marginTop: `1rem`,
                            marginBottom: `1rem`,
                        }}
                        src="images/employee.svg"
                    />
                    <div style={{ fontSize: `1.5rem`, fontWeight: `bold` }}>
                        Workforce management
                    </div>
                    <p style={{ color: `#535b6c`, height: `5rem` }}>
                        Get seamless updates on your workforce members, verify
                        and grant access to your employees & partners.
                    </p>
                    <div>
                        <StyledButton>Learn more</StyledButton>
                    </div>
                </Card.Body>
            </Card>
        </Col>
        <Col md="12" lg="4">
            <Card>
                <Card.Body>
                    <Image
                        style={{
                            height: `4rem`,
                            width: `4rem`,
                            marginTop: `1rem`,
                            marginBottom: `1rem`,
                        }}
                        src="images/time.svg"
                    />
                    <div style={{ fontSize: `1.5rem`, fontWeight: `bold` }}>
                        Clocker for employees
                    </div>
                    <p style={{ color: `#535b6c`, height: `5rem` }}>
                        Verify and independently validates data on platform
                        Allows easy application and registration to Business
                        services , embassy, sport etc.
                    </p>
                    <div>
                        <StyledButton>Learn more</StyledButton>
                    </div>
                </Card.Body>
            </Card>
        </Col>
        <Col md="12" lg="4">
            <Card>
                <Card.Body>
                    <Image
                        style={{
                            height: `4rem`,
                            width: `4rem`,
                            marginTop: `1rem`,
                            marginBottom: `1rem`,
                        }}
                        src="images/team-management.svg"
                    />
                    <div style={{ fontSize: `1.5rem`, fontWeight: `bold` }}>
                        Customer management
                    </div>
                    <p style={{ color: `#535b6c`, height: `5rem` }}>
                        Build secure, seamless and customizable experiences for
                        your customers
                    </p>
                    <div>
                        <StyledButton>Learn more</StyledButton>
                    </div>
                </Card.Body>
            </Card>
        </Col>
        <Col xs="12">
            <div
                style={{
                    textAlign: `center`,
                    color: `#2382b6`,
                    fontSize: `1.5rem`,
                    paddingTop: `2rem`,
                }}
            >
                View More Products
            </div>
        </Col>
    </Row>
);

export default OurProduct;
