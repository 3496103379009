import styled from '@emotion/styled';
import { Link } from 'gatsby';
import React, { ReactElement } from 'react';
import { Col, Card, Button } from 'react-bootstrap';
import { cardData } from '../../utils/entities';
import { Image } from '../../utils/image';
// import { StyledButton } from './Buttons';

const StyledButton = styled(Button)`
    border-radius: 100rem;
    color: #29558c;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    /* margin-right: 2rem; */
    border-style: solid;
    border-width: 0.5px;
    border-image-source: linear-gradient(to top, #9ba4d9, #94dfe7);
    border-image-slice: 1;
    background-image: linear-gradient(to bottom, #ffffff, #ffffff),
        linear-gradient(to top, #9ba4d9, #94dfe7);
    background-origin: border-box;
    background-clip: content-box, border-box;
    border: solid 1px transparent;
    box-shadow: 2px 1000px 1px #fff inset;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    &:hover {
        color: black;
    }
`;

const FeatureCard = ({
    imgUrl,
    title,
    description,
    slug,
    packages,
}: cardData): ReactElement => (
    <Col md="12" lg="4">
        <Card
            style={{
                color: `#535b6c`,
                margin: packages ? `1rem` : 0,
            }}
        >
            <Card.Body
                style={{
                    textAlign: `center`,
                    height: packages ? `30rem` : `auto`,
                }}
            >
                <Image
                    style={{
                        height: `5rem`,
                        width: `5rem`,
                        marginTop: `2rem`,
                        marginBottom: `2rem`,
                    }}
                    src={imgUrl}
                />
                <div
                    style={{
                        fontSize: `1.05rem`,
                        marginBottom: `1rem`,
                        fontWeight: `bold`,
                    }}
                >
                    <Link
                        style={{ color: `#535b6c`, textDecoration: `none` }}
                        to={slug}
                    >
                        {title}
                    </Link>
                </div>
                <p style={{ height: packages ? `10rem` : `7rem` }}>
                    {description}
                </p>
                {packages && (
                    <div>
                        <StyledButton>Learn more</StyledButton>
                    </div>
                )}
            </Card.Body>
        </Card>
    </Col>
);

export default FeatureCard;
